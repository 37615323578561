import React from "react";

import { PageProps } from "gatsby";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import DocumentationSection from "components/docs/DocumentationSection";
import AdvancedMappingSVG from "../../../assets/images/docs/guides/field-mapping/AdvancedMappingSVG.svg";
import CodeExample from "components/docs/code-examples/CodeExample";

const AdvancedMapping = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Advanced mapping";
  const description = "Apply advanced logic to access custom fields within objects or lists";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Add advanced mapping to a Remote Field" className="mb-9">
        <p>
          Advanced mapping is available for Remote Fields of type <b>array []</b> or{" "}
          <b>object {"{}"}</b> to help you only sync a specific field or to apply unique business
          logic. When you select an applicable field from the Remote Fields dropdown in the Merge
          dashboard or in Merge Link, you will see the advanced mapping option appear below.
          Clicking <b>Advanced mapping</b> will allow you to view the full response.
        </p>
        <div className="w-full flex items-center justify-center my-6">
          <AdvancedMappingSVG />
        </div>
        <p>
          The easiest way to create an advanced mapping is to click the value in the array or object
          you want to map, and Merge will auto-generate the expression.
        </p>
      </DocumentationSection>
      <hr />
      <DocumentationSection title="JMESPath basics" className="my-9">
        <p>
          Indexing in JMESPath is 0-based and allows you to select a specific element in a list. For
          example, the expression [1] applied to the array ["a", "b", "c", "d", "e", "f"] will
          result in "b".
        </p>
        <p>
          To implement advanced mapping with more complex logic, refer to the{" "}
          <a href="https://jmespath.org/tutorial.html" target="_blank">
            JMESPath tutorial
          </a>{" "}
          to write an expression. We recommend reaching out to our support representative if you
          need help.
        </p>
        <div className="bg-gray-0 rounded-lg px-6 py-5 mb-9">
          <div className="flex flex-row items-center">
            <div className="text-14 font-semibold">Generate JMESPath expression with AI</div>
          </div>
          You can provide ChatGPT with your array or object and ask it to get the field you want
          using JMESPath
        </div>
        <div className="flex flex-row justify-between gap-12">
          <div className="flex flex-col w-1/2">
            <p>
              In the following example, we want to get emailUri. To do this with ChatGPT simply ask
              "Get the email URI only if shortName is Personal E-mail using JMESPath and share only
              the snipped and the outputted value".
            </p>
            <ul>
              <li>To get emailURi by index, use the expression: "[0].emailUri"</li>
            </ul>
            <ul>
              <li>
                To get emailUri only if shortName is Personal E-mail, use the expression:
                [?nameCode.shortName == 'Personal E-mail'].emailUri | [0]
              </li>
            </ul>
          </div>

          <div className="w-1/2">
            <CodeExample
              folder="field-mapping/advanced-mapping"
              fileBaseName="advanced-mapping-example"
              colorScheme="dark"
              hasLineNumbers={false}
              isVerticallyPadded={false}
              hideCopyButtonWithHeader
              className="w-100"
            />
          </div>
        </div>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};
export default AdvancedMapping;
